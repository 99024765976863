import httqReq from "./request";

let uploadUrl = `http://192.168.1.101:9520/api/cloud/upload`; // 开发环境上传地址
if (process.env.NODE_ENV === "production") {
  uploadUrl = `https://aiot.hamptonhotels.com.cn/api/cloud/upload`;
}

// 登陆函数
export const login = (obj) => httqReq.postQS(`/login`, obj);

// 退出登陆
export const logout = (id) => httqReq.get(`/logout/` + id);

// 用户菜单权限列表获取
export const getUserJurisdiction = (obj) => httqReq.get(`/upms/menu/` + obj);
// 名称校验
export const nameVerify = (name, obj) =>
  httqReq.get(`/upms/${name}/check`, {
    params: obj,
  });
// 用户名称校验
export const usernameVerify = (name, obj) =>
  httqReq.get(`/upms/${name}/check/` + obj);

// 用户数据列表获取
export const getUserList = (obj) =>
  httqReq.get(`/upms/user`, {
    params: obj,
  });
// 添加用户
export const addUser = (obj) => httqReq.postQS(`/upms/user`, obj);
// 删除用户
export const delUser = (id) => httqReq.delete(`/upms/user/${id}`);
// 查看用户详情
export const detailsUser = (username) => httqReq.get(`/upms/user/${username}`);
// 编辑用户
export const editUser = (obj) => httqReq.putQS(`/upms/user`, obj); //`user/${obj.id}`

// 修改用户状态
// export const chageUserState = (uid, type) => httqReq.put(`cloud/user/${uid}/state/${type}`)

// 获取角色列表
export const getRoleList = (obj) =>
  httqReq.get(`/upms/role`, {
    params: obj,
  });
// 添加角色
export const addRole = (obj) => httqReq.postQS(`/upms/role`, obj);
// 编辑角色
export const editRole = (obj) => httqReq.putQS(`/upms/role`, obj);
// 删除角色
export const delRole = (obj) => httqReq.delete(`/upms/role/` + obj);

// 删除品牌
export const delBrand = (obj) => httqReq.delete(`/cloud/brand/` + obj);
// 删除门店
export const delHotel = (obj) => httqReq.delete(`/cloud/hotel/` + obj);
// 未来控二维码
export const getHetelQrCode = (data) =>
  httqReq.get(`/cloud/hotel/roomControlQRCode`, { params: data });

// 重置客房监控数据
export const roomStatusReset = (hotelId) =>
  httqReq.post(`/bi/roomStatus/reset/` + hotelId);

// GPP资产同步
export const gppSyncOpt = (hotelId) =>
  httqReq.post(`/cloud/hotel/gpp/sync/` + hotelId);

// 建筑名称校验
export const buildingNameVerify = (obj) =>
  httqReq.get(`/cloud/building/check`, {
    params: obj,
  });
// 删除建筑
export const delBuilding = (obj) => httqReq.delete(`/cloud/building/` + obj);
// 删除楼层
export const delFloor = (obj) => httqReq.delete(`/cloud/floor/` + obj);
// 删除客房
export const delRoom = (obj) => httqReq.delete(`/cloud/room/` + obj);
// 删除客房类型
export const delRoomType = (obj) => httqReq.delete(`/cloud/roomType/` + obj);
// 删除操作日志
export const delLog = (obj) => httqReq.delete(`/upms/log/` + obj);

// 删除字典
export const delDict = (obj) => httqReq.delete(`/upms/dict/` + obj);
// 删除菜单
export const delMenu = (obj) => httqReq.delete(`/upms/menu/` + obj);
// 修改故障处理
export const editDeviceFault = (obj) =>
  httqReq.get(`/cloud/deviceFault/` + obj);

// 给用户分配角色
export const grantUserRole = (id, rid) =>
  httqReq.putQS(`/upms/user/${id}/role`, {
    rid: rid,
  });
// 获取权限列表数据
export const getRights = (type) => httqReq.get(`/upms/menu/` + type);
// 获取权限ID列表数据
export const getRightsId = (type) => httqReq.get(`/upms/role/menu/` + type);
// 删除角色指定权限
export const delUserRight = (roleId, rightId) =>
  httqReq.delete(`/upms/roles/${roleId}/rights/${rightId}`);
// 角色授权
export const grantRoleRight = (roleId, rids) =>
  httqReq.postQS(`/upms/roles/${roleId}/rights`, {
    rids,
  });
// 左侧菜单权限获取
export const getMenus = () => httqReq.get(`/upms/menus`);
// 获取商品分类数据
export const getCates = (type, pagenum, pagesize) =>
  httqReq.get(`/cloud/categories`, {
    params: {
      type,
      pagenum,
      pagesize,
    },
  });
// 添加分类
export const addCate = (obj) => httqReq.postQS(`/cloud/categories`, obj);

// 品牌列表
export const getBrandList = () => httqReq.get(`/cloud/brand`);
//
export const brandNameVerify = () => httqReq.get(`/cloud/brand/check`);
// 品牌查询
export const getBrandSeek = (obj) =>
  httqReq.get(`/cloud/brand/`, {
    params: obj,
  });
export const wifiUpdate = (data) =>
  httqReq.post(`/cloud/brand/updateWifi`, data);

// 门店列表
export const getHotelList = (obj) =>
  httqReq.get(`/cloud/hotel`, {
    params: obj,
  });
// 门店查询
export const getHotelSeek = (obj) =>
  httqReq.get(`/cloud/hotel/`, {
    params: obj,
  });
// 门店初始化
export const hotelInitAdd = (data) => httqReq.post(`/cloud/hotelAdd/add`, data);
// 门店名称校验
export const hotelNameVerify = (obj) =>
  httqReq.get(`/cloud/hotel/check`, {
    params: obj,
  });

// 建筑查询
export const getBuildinglList = (obj) =>
  httqReq.get(`/cloud/building`, {
    params: obj,
  });
// 楼层查询
export const getFloorlList = (obj) =>
  httqReq.get(`/cloud/floor`, {
    params: obj,
  });
// 客房查询
export const getRoomlList = (obj) =>
  httqReq.get(`/cloud/room`, {
    params: obj,
  });
// 客房类型查询
export const getBoomTypelList = (obj) =>
  httqReq.get(`/cloud/roomType`, {
    params: obj,
  });

// 操作日志查询
export const getLoglList = (obj) =>
  httqReq.get(`/upms/log`, {
    params: obj,
  });
// 字典查询
export const getDictList = (obj) =>
  httqReq.get(`/upms/dict`, {
    params: obj,
  });
// 客房监控查询
export const getRoomStatusList = (obj) => httqReq.post(`/bi/roomStatus`, obj);
// 获取房间设备信息
export const getRoomInfo = (id) =>
  httqReq.post(`/bi/roomStatus/getInfo`, {
    gatewayUuid: id,
  });
// 获取房间设备信息
export const biHotelRoomDevice = (data) =>
  httqReq.post(`/bi/hotel/room/device`, data);
// 获取房间设备信息 r3000设备
export const getRoomInfoR3000 = (id) =>
  httqReq.post(`/cloud/eq/getInfo2`, {
    mainboardId: id,
  });

// mqtt发送
export const deviceControlReq = (obj) =>
  httqReq.post(`/cloud/mqtt/device/control`, obj);

export const seasonalControlReq = (obj) =>
  httqReq.post(`/cloud/mqtt/seasonal1/control`, obj);

export const seasonalControlReq2 = (obj) =>
  httqReq.post(`/cloud/mqtt/seasonal2/control`, obj);

export const checkPassWord = (obj) => httqReq.post(`/bi/roomStatus/check`, obj);

// 客房事件列表
export const getRoomStatused = (obj) =>
  httqReq.get(`/bi/roomStatused`, {
    params: obj,
  });

// 故障信息查询
export const getDeviceFaultList = (obj) =>
  httqReq.post(`/cloud/deviceFault`, obj);
// 故障监控查询
export const getDeviceFaultAlarmList = (obj) =>
  httqReq.post(`/bi/deviceFaultAlarm`, obj);
// 菜单管理查询
export const getMenuList = (obj) =>
  httqReq.get(`/upms/menu`, {
    params: obj,
  });
// 设备模式
export const setSeasonal = (obj) =>
  httqReq.get(`/bi/roomStatus/seasonal`, {
    params: obj,
  });

// 添加品牌
export const addBrand = (obj) => httqReq.postQS(`/cloud/brand`, obj);
// 添加门店
export const addHotel = (obj) => httqReq.post(`/cloud/hotel`, obj);
// 添加建筑
export const addBuilding = (obj) => httqReq.postQS(`/cloud/building`, obj);
// 添加楼层
export const addFloor = (obj) => httqReq.postQS(`/cloud/floor`, obj);
// 添加客房
export const addRoom = (obj) => httqReq.postQS(`/cloud/room`, obj);
// 添加客房类型
export const addRoomType = (obj) => httqReq.post(`/cloud/roomType`, obj);

// 添加字典
export const addDict = (obj) => httqReq.postQS(`/upms/dict`, obj);
// 日志导出
export const exportLog = (obj) =>
  httqReq.post(`/cloud/log/excel`, obj, {
    responseType: "arraybuffer",
  });
// 添加菜单
export const menuAdd = (obj) => httqReq.postQS(`/upms/menu`, obj);

// 获取品牌下的房间类型
export const getRoomTypelSelect = (id) =>
  httqReq.get(`/cloud/roomType/list/` + id);

// 获取酒店下的房间类型
export const getHotelRoomType = (id) =>
  httqReq.get(`/cloud/roomType/hotel/` + id);

// 品牌下拉框
export const getBrandSelect = (obj) =>
  httqReq.get(`/cloud/brand/list`, {
    params: obj,
  });
// 门店下拉框
export const getHotelSelect = (id) => httqReq.get(`/cloud/hotel/list/` + id);
// 建筑下拉框
export const getBuildingSelect = (id) =>
  httqReq.get(`/cloud/building/list/` + id);
// 楼层下拉框
export const getFloorSelect = (id) => httqReq.get(`/cloud/floor/list/` + id);
// 角色下拉框
export const getRoleSelect = (brandId, hotelId) =>
  httqReq.get(`/upms/role/list/${brandId}/${hotelId}`);

// 设备查询
export const getEqlList = (obj) =>
  httqReq.get(`/cloud/eq`, {
    params: obj,
  });
// 删除设备
export const delEq = (obj) => httqReq.delete(`/cloud/eq/` + obj);
// 添加设备
export const addEq = (obj) => httqReq.post(`/cloud/eq`, obj);
// 设备编辑
export const editEq = (obj) => httqReq.put(`/cloud/eq`, obj);
// 设备详情
// 设备编辑
export const getEqDetail = (id) => httqReq.get(`/cloud/eq/info/${id}`);
// 设备下拉框
export const getEqSelect = () => httqReq.get(`/cloud/eq/list/`);
// 获取设备类型
export const getEqTypeSelect = () =>
  httqReq.get(`/upms/dict/list`, {
    params: {
      parentId: "EQUIPMENT",
    },
  });
export const getEqTGlist = (type) => httqReq.get(`/cloud/eq/tgList/${type}`);

// 设备信息

export const getRcuInfoList = (page, size, data) =>
  httqReq.get(`/cloud/rcu/page/${page}/${size}`, {
    params: data,
  });

// 场景下拉数据
export const getSceneSelect = () =>
  httqReq.get(`/upms/dict/list`, {
    params: {
      parentId: "SCENE",
    },
  });
// 获取故障信息
export const getKeyySelect = () =>
  httqReq.get(`/upms/dict/list`, {
    params: {
      parentId: "FAULT",
    },
  });

// 品牌编辑
export const editBrand = (obj) => httqReq.putQS(`/cloud/brand`, obj);
// 门店编辑
export const editHotel = (obj) => httqReq.put(`/cloud/hotel`, obj);
// 建筑编辑
export const editBuilding = (obj) => httqReq.putQS(`/cloud/building`, obj);
// 楼层编辑
export const editFloor = (obj) => httqReq.putQS(`/cloud/floor`, obj);
// 客房编辑
export const editRoom = (obj) => httqReq.putQS(`/cloud/room`, obj);

export const editUUidReq = (obj) => httqReq.put(`/cloud/room/updateUUID`, obj);

// 客房类型编辑
export const editRoomType = (obj) => httqReq.putQS(`/cloud/roomType`, obj);

// 修改密码
export const editpassword = (obj) => httqReq.putQS(`/upms/user/password`, obj);
// 字典编辑
export const editDict = (obj) => httqReq.putQS(`/upms/dict`, obj);
// 菜单编辑
export const editMenu = (obj) => httqReq.putQS(`/upms/menu`, obj);

// 客房批量导入
export const roomImport = (obj) =>
  httqReq.post(`/cloud/room/import`, obj, {
    headers: {
      "Content-Type": "multipart/form-data;",
    },
  });

// 客房模板导出
export const roomTemplate = () =>
  httqReq.post(
    `/cloud/room/template`,
    {},
    {
      responseType: "arraybuffer",
    }
  );
// 客房事件信息导出
export const roomExport = () =>
  httqReq.post(
    `/bi/roomStatused/export`,
    {},
    {
      responseType: "arraybuffer",
    }
  );

// 操作效果
export const hotelOper = (obj) =>
  httqReq.postQS(`/cloud/room/sync/dueros`, obj);

export const hotelOperTcl = (obj) => httqReq.post(`/cloud/room/sync/tcl`, obj);

//授权列表
export const getOauthUser = (obj) =>
  httqReq.get(`/cloud/oauthUser/list`, {
    params: obj,
  });
//添加授权用户
export const addOauth = (obj) => httqReq.post(`/cloud/oauthUser/add`, obj);
//编辑授权用户
export const updateOauth = (obj) => httqReq.put(`/cloud/oauthUser/update`, obj);
//批量删除授权用户
export const delOauths = (id) =>
  httqReq.delete(`/cloud/oauthUser/deleteUsers/${id}`);

// 飞房列表
export const feifangList = (page, size, obj) =>
  httqReq.get(`/bi/feifang/feifangList/${page}/${size}`, {
    params: obj,
  });

export const exportfeifangList = (page, size, obj) =>
  httqReq.postQS(`/bi/feifang/export/feifangList/${page}/${size}`, obj, {
    responseType: "arraybuffer",
  });
// 飞房统计列表
// export const feifangRoomList = (page, size, obj) => httqReq.get(`/bi/feifang/feifangRoomList/${page}/${size}`, { params: obj })
export const feifangHotelList = (page, size, obj) =>
  httqReq.get(`/bi/feifang/feifangHotelList/${page}/${size}/1`, {
    params: obj,
  });
// 导出飞房酒店
export const exportfeifangHotelList = (page, size, obj) =>
  httqReq.export(`/bi/feifang/export/feifangHotelList/${page}/${size}/1`, obj, {
    responseType: "arraybuffer",
  });

// 授权推送 二维码
export const getQrCode = () => httqReq.get(`/message/wx/getBindQr`);

//     工单
export const GET_WORK = (data = {}) =>
  httqReq.get(`/bi/work/findUntreatedWork`, data);

//     获取 sos 报警数量列表 实时报警统计
export const GET_SOS_LIST = (data = {}) =>
  httqReq.get(`/bi/hotelViewBoard/sosAlarm`, data);

//	门磁报警
export const GET_Sensor = (data = {}) =>
  httqReq.get(`/bi/hotelStatistics/doorMagnetic`, data);

//    温度
export const GET_Temperature = (data = {}) =>
  httqReq.get(`/bi/hotelStatistics/temperatureException`, data);

//    请求清理
export const GET_Clean = (data = {}) =>
  httqReq.get(`/bi/hotelViewBoard/clear`, data);

//    设备掉线
export const GET_Drops = (data = {}) =>
  httqReq.get(`/bi/hotelViewBoard/deviceOffline`, data);

/****************以下是 home 页面的 api************************* */
// 入住退房时间分布
export const get_checkInOutTime = (
  data = {
    roleType: 0,
  }
) => httqReq.get(`/bi/information/findDistributionOfTime/${data.roleType}`);

// 获取分店地图
export const GET_BRAND_MAP = (data = {}) =>
  httqReq.get(`/bi/information/findBrandBranchMap`, data);

// 获取地图数据 各省份 各状态门店统计
export const GET_ProvinceDetails = (data = {}) =>
  httqReq.get(`/bi/information/findProvinceDetails`, data);

// 获取分店数及房间数
export const GET_BRAND_ROOM = (data = {}) =>
  httqReq.get(`/bi/information/findBrandRegionNumber`, data);

// 获取房间的取电总量
export const GET_ROOM_ELECTRICITY = (
  data = {
    roleType: 0,
  }
) => httqReq.get(`/bi/information/takeElectricityNumber/${data.roleType}`);

// 能耗统计
export const powerElectric = () => httqReq.get(`/bi/power/electric`);

// 获取七日内的pms出租
export const GET_SEVEN_PMS = (
  data = {
    roleType: 0,
  }
) => httqReq.get(`/bi/information/findBrandPmsOccupancyRate/${data.roleType}`);
export const GET_MONTH_PMS = (
  data = {
    roleType: 0,
  }
) => httqReq.get(`/bi/information/monthlyRateList/${data.roleType}`);

// 获取出租率情况
export const GET_OCCUPANCY_INFO = (data = {}) => {
  const { order, condition } = data;
  return httqReq.get(
    `/bi/information/findHotelPmsOccupancyRate/${order}/${condition}`
  );
};
//获取sos报警数量列表
export const GET_SosStores = (data = {}) =>
  httqReq.get(`/bi/brandViewBoard/sosCountList`, data);
//获取soso报警房间
export const GET_Room = (data = {}) =>
  httqReq.get(`/bi/brandViewBoard/sosRoomList/${data.hotelId}`);

// 获取离线设备门店
export const GET_Offlinesotres = (data = {}) =>
  httqReq.get(`/bi/brandViewBoard/deviceOfflineList`);

export const GET_OfflinRoom = (data = {}) =>
  httqReq.get(`/bi/brandViewBoard/deviceOfflineRoomList/${data.hotelId}`);

// 获取疑似飞房列表
export const GET_feifangList = (data = {}) =>
  httqReq.get(`/bi/brandViewBoard/feifangHotelList`);
// 疑似飞房房间
export const GET_feifangRoom = (data = {}) =>
  httqReq.get(`/bi/brandViewBoard/feifangRoomList/${data.hotelId}`);

export const GET_feifangRoom_jt = (data = {}) =>
  httqReq.get(`/bi/brandViewBoard/feifangRoomList`);

export const GET_checkInRoomType = (data = {}) =>
  httqReq.get(`/bi/information/findCheckInRoomType`);

// 取电时长统计
export const electricityRate = (roleType = 0) =>
  httqReq.get(`/bi/information/electricityRate/${roleType}`);

// 整点取电数
export const hourlyElectricity = (roleType = 0) =>
  httqReq.get(`/bi/information/hourlyElectricity/${roleType}`);

// 地图获取运营分店数、客房数、取电房间数
export const findProvinceDetails2 = () =>
  httqReq.get(`/bi/information/findProvinceDetails2`);

// 实时开房数
export const rentedCount = (roleType = 0) =>
  httqReq.get(`/bi/information/rentedCount/${roleType}`);

// 获取柱状图 分店数 客房数 各状态分店
export const findBrandRegionNumber2 = () =>
  httqReq.get(`/bi/information/findBrandRegionNumber2`);

// /information/findProvinceDetails
// 前台 工程 看板数据
//  sos报警
export const GET_SOS_TYPE_1 = (data = {}) =>
  httqReq.get(`/bi/brandViewBoard/sosRoomList`);
// 设备离线
export const GET_Offline_TYPE_1 = (data = {}) =>
  httqReq.get(`/bi/brandViewBoard/deviceOfflineRoomList`);
// 温度异常
export const GET_temperatureException_TYPE_1 = (data = {}) =>
  httqReq.get(`/bi/brandViewBoard/temperatureException`);
//  门磁报警
export const GET_doorMagnetic_TYPE_1 = (data = {}) =>
  httqReq.get(`/bi/brandViewBoard/doorMagnetic`);
// 请求清理
export const GET_requestClear_TYPE_1 = (data = {}) =>
  httqReq.get(`/bi/brandViewBoard/requestClear`);
export const getHotelName = (data = {}) =>
  httqReq.get(`/bi/information/getHotelTitle`);

// 获取授权码列表
export const getCodeList = (pageNo, pageSize, data) =>
  httqReq.get(`/cloud/mini/authCode/getCodeList/${pageNo}/${pageSize}`, {
    params: data,
  });
// 授权码详情
export const getCodeById = (id) =>
  httqReq.get(`/cloud/mini/authCode/getCode/${id}`);
// 授权码新增
export const insertCode = (data) =>
  httqReq.post(`/cloud/mini/authCode/createCode`, data);
// 授权码修改
export const updateCodeById = (data) =>
  httqReq.post(`/cloud/mini/authCode/updateCode`, data);
// 授权码删除
export const delCodeById = (arr) =>
  httqReq.delete(`/cloud/mini/authCode/deleteCode`, {
    data: arr,
  });

// 异常记录

// 阈值调整查询
export const warningSettingGet = () => httqReq.get(`/bi/warning/setting/get`);

export const warningSettingAdd = (data) =>
  httqReq.post(`/bi/warning/setting/add`, data);
export const warningSettingUpdate = (data) =>
  httqReq.post(`/bi/warning/setting/update`, data);

export const warningList = (data) =>
  httqReq.get(`/bi/warning/list`, {
    params: data,
  });
// hotelId
// roomNumber
// mainboardId
// mainfarmeType     R1000/R2000    R3000
// version           String  设备固件版本  1.6.2
// startDate   String 2020-01-01 01:01:01
// endDate     String   2021-01-01 01:01:01
// warningType    Integer  故障类型    异常类型 0门铃异响  1设备离线  2自动重启  3UUID重复  4房间号错误

// 做房查房
export const makeRoomList = (pageNo, pageSize, data) =>
  httqReq.get(`/cloud/opsDaily/opsList/${pageNo}/${pageSize}`, {
    params: data,
  });

export const makeRoomOpsRate = (data) =>
  httqReq.get(`/cloud/opsDailyHotel/opsRate`, {
    params: data,
  });

// 人员行踪分析列表
export const trackList = (pageNo, pageSize, data) =>
  httqReq.get(`/cloud/opsDaily/opsDailyTrack/${pageNo}/${pageSize}`, {
    params: data,
  });

export const trackDetail = (data) =>
  httqReq.get(`/cloud/opsDaily/opsDailyList`, {
    params: data,
  });

// 客房管理明细
export const roomManageList = (pageNo, pageSize, data) =>
  httqReq.get(`/cloud/opsDaily/opsManageList/${pageNo}/${pageSize}`, {
    params: data,
  });

// 取电卡列表
export const cardList = (pageNo, pageSize, data) =>
  httqReq.get(`/cloud/card/list/${pageNo}/${pageSize}`, {
    params: data,
  });

// 删除取电卡
export const deleteCard = (id) => httqReq.delete(`/cloud/card/delete/${id}`);

// 员工列表
export const workerList = (pageNo, pageSize, data) =>
  httqReq.get(`/cloud/employee/list/${pageNo}/${pageSize}`, {
    params: data,
  });
// 新增
export const workerAdd = (data) => httqReq.post(`/cloud/employee/add`, data);
// 解绑
export const workerUnbind = (data) =>
  httqReq.post(`/cloud/employee/unbind`, data);
// 禁用
export const workerBan = (data) => httqReq.post(`/cloud/card/update`, data);
// 换卡
export const workerChangeCard = (data) =>
  httqReq.post(`/cloud/employee/changeCard`, data);
// 员工删除
export const workerDel = (id) => httqReq.post(`/cloud/employee/delete/${id}`);
// 员工信息修改
export const workerInfoUpdate = (data) => httqReq.put(`/cloud/employee`, data);

// 模板导出
export const workersDownTemplate = () =>
  httqReq.post(
    `/cloud/employee/template`,
    {},
    {
      responseType: "arraybuffer",
    }
  );
export const workerImport = (data) =>
  httqReq.post(`/cloud/employee/import`, data);

// 员工授权派单
export const getOpsBindQr = (id) =>
  httqReq.get(`/message/wx/getOpsBindQr/${id}`);

// 消息推送记录
export const getMessageLog = (data) =>
  httqReq.get(`/cloud/weChatMessageLog/fingWeChatMessageLog`, {
    params: data,
  });

// 工单列表
export const getWorkOrderList = (pageNo, pageSize, data) =>
  httqReq.get(`/cloud/order/orderList/${pageNo}/${pageSize}`, {
    params: data,
  });
// 分派人员列表
export const getWorkerList = (data) =>
  httqReq.get(`/cloud/order/findEmployees`, {
    params: data,
  });
// 分派、返工
export const updateOrderDetail = (data) =>
  httqReq.put(`/cloud/order/apportionOrRemarkOrder`, data);
// 验收工单
export const finishWorkOrder = (data) =>
  httqReq.put(`/cloud/order/acceptanceOrder`, data);
// 删除工单
export const deleteWorkOrder = (id) =>
  httqReq.delete(`/cloud/order/deleteOrder/${id}`);

export const workOrderAdd = (data) =>
  httqReq.post(`/cloud/order/createOrder`, data);

export const importOrderTemp = (data) =>
  httqReq.post(`/cloud/order/template`, data, {
    responseType: "arraybuffer",
  });

export const workOrderImport = (data) =>
  httqReq.post(`/cloud/order/import`, data);

export const getPanelList = (pageNo, pageSize, data) =>
  httqReq.get(`/cloud/panel/${pageNo}/${pageSize}`, {
    params: data,
  });

// 门牌
export const panelAdd = (data) => httqReq.post(`/cloud/panel`, data);

export const panelEdit = (data) => httqReq.put(`/cloud/panel`, data);

export const panelDetele = (id) => httqReq.delete(`/cloud/panel/${id}`);

export const panelSync = (id) => httqReq.post(`/cloud/panel/syn/${id}`);

export const getPanelNotSet = () => httqReq.get(`/cloud/panel/notset`);

export const panelImport = (obj) =>
  httqReq.post(`/cloud/panel/import`, obj, {
    headers: {
      "Content-Type": "multipart/form-data;",
    },
  });

// 广告
export const getAdsList = (pageNo, pageSize, data) =>
  httqReq.get(`/cloud/advertise/${pageNo}/${pageSize}`, {
    params: data,
  });

export const getAdsDetailList = (pageNo, pageSize, data) =>
  httqReq.get(`/cloud/advertise/detail/${pageNo}/${pageSize}`, {
    params: data,
  });

export const advertiseAdd = (data) => httqReq.post(`/cloud/advertise`, data);

export const advertiseSync = (data) =>
  httqReq.post(`/cloud/advertise/syn`, data);

export const advertiseDelete = (advId) =>
  httqReq.delete(`/cloud/advertise/${advId}`);

export const advertiseClean = (pmsHotelId) =>
  httqReq.delete(`/cloud/advertise/clean/${pmsHotelId}`);

// 科飞设备管理
export const getKooFeeApplianceList = (data) =>
  httqReq.post(`/cloud/koofee/app/page`, data);
export const addKooFeeAppliance = (data) =>
  httqReq.post(`/cloud/koofee/app/add`, data);
export const deleteKooFeeAppliance = (id) =>
  httqReq.post(`/cloud/koofee/app/delete/${id}`);

// 科飞场景配置
export const getKooFeeSceneList = (data) =>
  httqReq.post(`/cloud/koofee/scene/page`, data);
export const eidtKooFeeScene = (data) =>
  httqReq.post(`/cloud/koofee/scene/edit`, data);
export const deleteKooFeeScene = (id) =>
  httqReq.post(`/cloud/koofee/scene/delete/${id}`);

// X1主机管理
export const getGatewayRegisterList = (data) =>
  httqReq.post(`/bi/gateway/register/page`, data);
export const gatewayBindRoom = (data) =>
  httqReq.post(`/cloud/room/bindMac`, data);

// PMS Data
export const getPmsDataList = (data) =>
  httqReq.post(`/cloud/pmsData/page`, data);
export const getOriginPmsDataList = (data) =>
  httqReq.post(`/cloud/pmsData/origin/page`, data);

// 入住机人脸数据
export const getPersonDataList = (data) =>
  httqReq.post(`/cloud/person/page`, data);

// 插拔卡记录
export const getElectricDataList = (data) =>
  httqReq.post(`/bi/electric/page`, data);

// 上传图片、视频地址
export const imgUploadUrl = `${uploadUrl}?type=`;
// 上传图片、视频
export const imgUploadReq = (type, data) =>
  httqReq.post(`${imgUploadUrl}${type}`, data);

export const getRoomTypeTotal = (hotelId = 1) =>
  httqReq.get(`/cloud/workbench/roomTypeCount`, {
    params: { hotelId: hotelId },
  });

export const getRoomTotal = (hotelId = 1) =>
  httqReq.get(`/cloud/workbench/roomCount/${hotelId}`);

// 报警
export const getHotelWarning = (hotelId = 1) =>
  httqReq.get(`/cloud/workbench/warningMessage`, {
    params: { hotelId: hotelId },
  });

export const getServiceOrderCount = (hotelId = 1) =>
  httqReq.get(`/cloud/workbench/serviceOrderCount`, {
    params: { hotelId: hotelId },
  });

export const getServiceOrders = (pageNo, pageSize, data) =>
  httqReq.get(`/cloud/workbench/serviceOrderList/${pageNo}/${pageSize}`, {
    params: data,
  });

// 指派工单
export const orderUpdateBatch = (data) =>
  httqReq.put(`/cloud/order/apportionOrRemarkOrderList`, data);

// 批量生成并指派工单
export const faultOrderBatch = (data) =>
  httqReq.put(`/cloud/order/faultOrder`, data);

export const getMyMenus = () => httqReq.get(`/cloud/workbench/myMenuList`);

export const getAllMenu = () => httqReq.get(`/cloud/workbench/menuList`);

export const saveMyMenus = (data) =>
  httqReq.put(`/cloud/workbench/updateMyMenuList`, data);

export const gettrackList = (data) =>
  httqReq.get(`/cloud/workbench/trackList`, { params: data });

export const getTopIndex = (data) =>
  httqReq.get(`/cloud/workbench/rankingList`, { params: data });

export const GET_TIAN_QI = (data) =>
  httqReq.get(`/cloud/workbench/rankingList`, { params: data });
// axios.defaults.baseURL = baseURL
// axios.defaults.timeout =  6000;

export const GET_HOTEL_NAME = (id) =>
  httqReq.get(`/cloud/workbench/getHotelTitle/${id}`);

// 7天工作量
export const GET_7DAYS_WORK = (data) =>
  httqReq.get(`/cloud/workbench/dailyOpsCount`, { params: data });

export const GET_7DAYS_WORK_LIST = (page, size, data) =>
  httqReq.get(`/cloud/workbench/dailyOpsDetail/${page}/${size}`, {
    params: data,
  });

// 入住情况
export const GET_7DAYS_CHECK_IN = (data) =>
  httqReq.get(`/cloud/workbench/dailyDeviceRentedRate`, { params: data });

export const GET_7DAYS_CHECK_IN_LIST = (page, size, data) =>
  httqReq.get(`/cloud/workbench/dailyDeviceRentedRateList/${page}/${size}`, {
    params: data,
  });

// 飞房监控趋势 https://v7.apipost.cn/#/apis/run
export const biFeiFangTendencyStatistics = (params) =>
  httqReq.get(`/bi/feifang/tendency/statistics`, { params });
// 重点监控酒店 https://v7.apipost.cn/#/apis/run
export const biFeiFangTopPage = (data) =>
  httqReq.post(`/bi/feifang/top/page`, data);
// 异常类型分布 https://v7.apipost.cn/#/apis/run
export const biFeiFangWarn = (params) =>
  httqReq.get(`/bi/feifang/warn`, { params });
// 异常类型分布-列表 https://v7.apipost.cn/#/apis/run
export const biFeiFangWarnPage = (data) =>
  httqReq.post(`/bi/feifang/warn/page`, data);
// 汇总统计（疑似飞房、RCU离线） https://v7.apipost.cn/#/apis/run
export const biFeiFangCollectStatistics = (params) =>
  httqReq.get(`/bi/feifang/collect/statistics`, { params });
// 飞房实时监控数据(地图分布) https://v7.apipost.cn/#/apis/run
export const biFeiFangMapStatistics = (params) =>
  httqReq.get(`/bi/feifang/map/statistics`, { params });
// 按地区统计飞房数据 https://v7.apipost.cn/#/apis/run
export const biFeiFangMapAreaStatistics = (params) =>
  httqReq.get(`/bi/feifang/area/statistics`, { params });

// RCU离线监控 https://v7.apipost.cn/#/apis/run
export const biFeiFangOffLineStatistics = (data) =>
  httqReq.post(`/bi/feifang/offline/statistics`, data);
// RCU离线监控 https://v7.apipost.cn/#/apis/run
export const biFeiFangOffLinePage = (data) =>
  httqReq.post(`/bi/feifang/offline/page`, data);
// 飞房统计 https://v7.apipost.cn/#/apis/run
export const biFeiFangStatistics = (data) =>
  httqReq.post(`/bi/feifang/statistics`, data);

/* 
    酒店工作台
  */
// 酒店工作台 https://v7.apipost.cn/#/apis/run
export const biHotelRoomStatus = (data) =>
  httqReq.post(`/bi/hotel/room/status`, data);

// 获取酒店的季节模式配置 - 在新系统 http://192.168.1.222:3000/project/13/interface/api/916
export const productSeasonConfigHotel = (params) =>
  httqReq.get(`/api/product/season_config/hotel`, { params });
//创建硬件产品 http://192.168.1.222:3000/project/13/interface/api/251
export const productAdd = (data) => {
  return httqReq.post("/api/product/product", data);
};
//更新硬件产品 http://192.168.1.222:3000/project/13/interface/api/275
export const productPut = (data) => {
  return httqReq.put("/api/product/product", data);
};
//批量下发季节模式 http://192.168.1.222:3000/project/13/interface/api/918
export const productRcuConfigSeason = (params, type) => {
  return httqReq.get("/api/product/rcu/config/season", { params }, type);
};
//房间更新-季节模式
export const rcuConfigSeason = (data) => {
  return httqReq.post("/api/product/rcu/config/season", data);
};
//房间设备控制 https://v7.apipost.cn/#/apis/run
export const cloudMqttDeviceControlV2 = (data) => {
  return httqReq.post("/cloud/mqtt/device/control/v2", data);
};

//下发结果查询 http://yapi.wanrun.pro/project/13/interface/api/776
export const rcuConfigResult = (params) => {
  return httqReq.get("/api/product/rcu/config/result", { params });
};
//授权码校验 https://v7.apipost.cn/#/apis/run
export const upmsUserPwdCheck = (data) => {
  return httqReq.post("/upms/user/pwd/check", data);
};
//PMS录入 https://v7.apipost.cn/#/apis/run
export const biHotelPms = (data) => {
  return httqReq.post("/bi/hotel/pms", data);
};

//导出飞房重点监控酒店排名前20表格
export const biFeiFangTopPageExport = (params) => {
  return httqReq.get("/bi/feifang/top/page/export", {
    params,
    responseType: "blob",
  });
};
//导出飞房统计查询
export const biFeiFangStatisticsExport = (params) => {
  return httqReq.get("/bi/feifang/statistics/export", {
    params,
    responseType: "blob",
  });
};
//导出 RCU离线监控（≥2小时）
export const biFeiFangOffLineStatisticsExport = (params) => {
  return httqReq.get("/bi/feifang/offline/statistics/export", {
    params,
    responseType: "blob",
  });
};
//导出 RCU离线明细
export const biFeiFangOfflinePageExport = (params) => {
  return httqReq.get("/bi/feifang/offline/page/export", {
    params,
    responseType: "blob",
  });
};
//导出 RCU离线明细
export const biFeiFangWarnPageExport = (params) => {
  return httqReq.get("/bi/feifang/warn/page/export", {
    params,
    responseType: "blob",
  });
};

//空调预设-编辑 http://192.168.1.222:3000/project/58/interface/api/2257
export const cloudPreSettingAirPost = (data) => {
  return httqReq.post("/cloud/pre/setting/air", data);
};
//空调预设-查询 http://192.168.1.222:3000/project/58/interface/api/2266
export const cloudPreSettingAirGet = (data) => {
  return httqReq.get("/cloud/pre/setting/air", data);
};
//设备集控 http://192.168.1.222:3000/project/58/interface/api/2275
export const cloudGroupControl = (data) => {
  return httqReq.post("/cloud/group/control", data);
};
