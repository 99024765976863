// 这个文件用来封装接口，发送请求
import axios from "axios";
import QS from "qs";
import store from "../vuex/index";
import moment from "moment";
import { MessageBox, Message } from "element-ui";

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

moment.locale("zh-cn");

// 请求携带cookie
axios.defaults.withCredentials = false;
axios.interceptors.request.use(
  function (config) {
    config.timeout = 60000; // 连接超时时间
    // 有 token 就带上 sessionStorage
    let token = store.state.token;
    if (token) {
      // Authorization字段不是随便加的，它是前后台约定好的字段
      config.headers.Authentication = token;
    }
    // 新系统接口使用新的baseUrl
    if (/\/api\/product/.test(config.url)) {
      config.baseURL = process.env.VUE_APP_BASE_URL_AIOT;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
// 拦截响应
axios.interceptors.response.use(
  (config) => {
    let code = config.data.code;
    if (code === "100000") {
      store.commit("SET_TOKEN");
      sessionStorage.clear();
      location.reload();
    } else if (code !== undefined && code != "000000") {
      Message.error(config.data.message);
    }
    // 新系统异常处理
    const status = config.status;
    if (status !== 200 && status !== 201 && status !== 204) {
      MessageBox.alert(`${data.msg || "连接异常"}`, "提示", {
        type: "error",
      });
    }
    return config;
  },
  (error) => {
    if (error.response) {
      let errorMessage =
        error.response.data === null
          ? "系统内部异常，请联系网站管理员"
          : error.response.data.error;
      switch (error.response.status) {
        case 404:
          Message.error("很抱歉，资源未找到");
          break;
        case 400:
          Message.error(errorMessage);
          break;
        case 401:
          // console.log('很抱歉，登录已过期，请重新登录------------------',error.response);
          MessageBox.alert(
            "很抱歉！已在其他地方登陆！请重新登录",
            "已在其他地方登陆！",
            {
              type: "error",
              confirmButtonText: "重新登录",
              callback: () => {
                return new Promise((resolve, reject) => {
                  store.commit("SET_TOKEN");
                  sessionStorage.clear();
                  location.reload();
                });
              },
            }
          );

          break;
        default:
          Message.error(
            `${errorMessage || error.response.data.msg || "连接异常"}`
          );
          break;
      }
    }
    // 请求响应超时
    else if (
      error.code === "ECONNABORTED" &&
      error.message &&
      error.message.indexOf("timeout") !== -1
    ) {
      MessageBox.alert("请求/响应超时，请稍后重试", "提示", {
        type: "error",
      });
    } else {
      MessageBox.alert("当前网络不可用，请检查您的网络设置", "提示", {
        type: "error",
      });
    }
    return Promise.reject(error);
  }
);

export default {
  delete(url, params) {
    return axios.delete(url, params, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  get(url, params, type) {
    return axios.get(url, params, {
      ...type,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  post(url, params, type) {
    return axios.post(url, params, {
      ...type,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  put(url, params, type) {
    return axios.put(url, params, {
      ...type,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  postQS(url, params, type) {
    return axios.post(
      url,
      QS.stringify({
        ...params, // 将参数变成  a=xx&b=xx&c=xx这样的参数列表
      }),
      {
        ...type,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
  },
  putQS(url, params, type) {
    return axios.put(
      url,
      QS.stringify({
        ...params, // 将参数变成  a=xx&b=xx&c=xx这样的参数列表
      }),
      {
        ...type,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
  },
};
